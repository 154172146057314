import React from "react";
import ResultSVG from "../assets/Group (2).svg";
import arrow from "../assets/Vector.svg";
import commentBox from "../assets/Group 25.svg";
import { useNavigate } from "react-router-dom";

const diseasesData = {
	diseases: [
		{
			name: "Powdery Mildew",
			description:
				"Powdery mildew is a common fungal disease that affects many plants, leaving a white or gray powdery coating on leaves and stems. It can cause stunted growth and reduce yields.",
			cure: "To control powdery mildew, remove affected leaves and destroy them. Spray plants with a fungicide containing sulfur or neem oil.",
            url: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Downy_and_Powdery_mildew_on_grape_leaf.JPG"
		},
		{
			name: "Black Spot",
			description:
				"Black spot is a fungal disease that affects roses and other ornamental plants, causing black spots on leaves and reducing plant vigor.",
			cure: "To control black spot, remove affected leaves and destroy them. Spray plants with a fungicide containing copper or sulfur.",
            url: "https://upload.wikimedia.org/wikipedia/commons/2/23/Black_spot.jpg"
		},
		{
			name: "Blossom End Rot",
			description:
				"Blossom end rot is a physiological disorder that affects tomatoes and other fruiting plants, causing a dark, sunken spot on the bottom of the fruit.",
			cure: "To prevent blossom end rot, maintain even soil moisture and avoid over-fertilizing. Apply a calcium supplement to the soil.",
            url : "https://i0.wp.com/savvygardening.com/wp-content/uploads/2020/07/tomato_blossom_end_rot.jpg?resize=600%2C400&ssl=1"
		},
		{
			name: "Fusarium Wilt",
			description:
				"Fusarium wilt is a fungal disease that affects many plants, including tomatoes, cucumbers, and melons, causing leaves to wilt and turn yellow. The disease can also affect the fruit and reduce yields.",
			
                cure: "To control fusarium wilt, remove affected plants and destroy them. Rotate crops and avoid planting susceptible plants in the same area for several years. Use a fungicide containing chlorothalonil or copper.",
		   url : "https://cdn.britannica.com/77/183477-050-B48BF328/Tomato-plant-fusarium-disease.jpg"
            },
		{
			name: "Early Blight",
			description:
				"Early blight is a fungal disease that affects tomatoes and other plants, causing dark spots on leaves and reducing plant vigor. The disease can also affect the fruit and reduce yields.",
			cure: "To control early blight, remove affected leaves and destroy them. Apply a fungicide containing chlorothalonil or copper. Use a mulch to prevent spores from splashing onto the plants.",
            url: "https://www.planetnatural.com/wp-content/uploads/2012/12/potato-blight.jpg"
		},
		{
			name: "Late Blight",
			description:
				"Late blight is a fungal disease that affects tomatoes and potatoes, causing dark, water-soaked spots on leaves and stems. The disease can quickly destroy entire crops.",
			cure: "To control late blight, remove affected plants and destroy them. Apply a fungicide containing chlorothalonil or copper. Rotate crops and avoid planting susceptible plants in the same area for several years.",
            url: "https://content.peat-cloud.com/w400/tomato-late-blight-tomato-1556463954.jpg"
		},
	],
};

function Learn() {
	const navigate = useNavigate();

	return (
		<>
			<div
				style={{
					backgroundColor: "#F9DFBB",
					margin: 0,
					padding: 0,
				}}
			>
				<div
					style={{ paddingTop: "3rem", marginLeft: "5rem" }}
					onClick={() => navigate("/")}
				>
					<img src={arrow} alt="alt" />
				</div>
				<div
					style={{
						display: "flex",
						width: "80%",
						margin: " 0 auto 0 auto",
						alignItems: "center",
						justifyContent: "center",
                        flexWrap: "wrap",
					}}
				>
					{diseasesData.diseases.map((disease) => (
						<div
							key={disease.name}
							style={{
								backgroundColor: "white",
								padding: "20px",
								margin: "20px",
								borderRadius: "10px",
                                width: "350px",
                                height: "500px",
                                border: "1px solid brown"
							}}
						>
                            <img style={{height: "200px", width: "350px"}} src={disease?.url} alt="alt"/>
							<h2 style={{color: "brown"}}>{disease.name}</h2>
							<p>{disease.description}</p>
							<p>
								<strong>Cure:</strong> {disease.cure}
							</p>
						</div>
					))}
				</div>
				<div style={{ marginTop: "-2%" }}>
					<img src={ResultSVG} alt="alt" style={{ width: "100%" }} />
				</div>
			</div>
		</>
	);
}

export default Learn;
