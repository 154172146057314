import React from "react";
import arrow from "../assets/Vector.svg";
import { ButtonStyles } from "./Home.styled";
import Objects from '../assets/OBJECTS (4).svg';
import { useNavigate } from "react-router-dom";

function Predict() {
    const navigate = useNavigate();

	return (
		<div style={{ backgroundColor: "#F9DFBB", margin: 0, padding: 0, height: "100vh" }} className='dvvid'>
			<div style={{ paddingTop: "3rem", marginLeft: "5rem" }} onClick={() => navigate('/')}>
				<img src={arrow} alt="alt" />
			</div>
			<div style={{ marginTop: "3vh" }}>
				<h1
					style={{ textAlign: "center", fontWeight: "700", fontSize: "56px" }}
				>
					CROP PREDICTION
				</h1>
			</div>
			<div
				style={{
					width: "70%",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
                <div style={{width: "70%", height: "30vh", backgroundColor: "white", margin: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<label class="custom-file-upload">
    <input type="file"/>
    Choose Image to Upload
</label>
                </div>
				<div
					style={{
						marginTop: "4rem",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<button style={ButtonStyles} onClick={() => navigate('/result')}>Evaluate</button>
				</div>
			</div>
		</div>
	);
}

export default Predict;
