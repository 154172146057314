export const HeroDiv_1 = {
	height: "100vh",
	backgroundColor: "#F9DFBB",
	margin: 0,
	padding: 0,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};
export const HeroDiv_2 = {
	height: "100vh",
    margin: 0,
	padding: 0,
	backgroundColor: "#FFF6EB",
};
export const HeroDiv_3 = {
	height: "100vh",
    margin: 0,
	padding: 0,
	backgroundColor: "#FFF6EB",
};
export const HeroTypography = {
	fontSize: "72px",
	fontWeight: "700",
	fontStyle: "normal",
	textAlign: "center",
	paddingTop: "1em",
};
export const ButtonStyles = {
	background:
		"linear-gradient(0deg, #FFC05C, #FFC05C), linear-gradient(94.83deg, #FFD9D4 23.57%, #8BC6FC 100%)",
	border: "1px solid #000000",
	borderRadius: "20px",
	fontSize: "32px",
	fontWeight: "600",
	padding: "1.25rem",
	lineHeight: "43px",
	textTransform: "uppercase",
};
export const innerContainerStyle = {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        width: "90%",
        margin: "auto",
        paddingTop: "4rem",
};
