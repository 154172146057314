import React from "react";
import arrow from "../assets/Vector.svg";
import ResultSVG from "../assets/Group (2).svg";
import commentBox from "../assets/Group 25.svg";
import { useNavigate } from "react-router-dom";

function Result() {
	const navigate = useNavigate();

	return (
		<>
			<div
				style={{
					backgroundColor: "#F9DFBB",
					margin: 0,
					padding: 0,
					height: "100vh",
				}}
			>
				<div
					style={{ paddingTop: "3rem", marginLeft: "5rem" }}
					onClick={() => navigate("/")}
				>
					<img src={arrow} alt="alt" />
				</div>
				<div
					style={{
						display: "flex",
						width: "80%",
						margin: " 0 auto 0 auto",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img src={commentBox} alt="commentBox" />
					<div style={{ position: "absolute", margin: "auto" }}>
						<p
							style={{
								fontSize: "40px",
								fontWeight: "400",
								textAlign: "center",
								marginTop: "-3%",
							}}
						>
							Your Plant is Affected By
						</p>
						<p
							style={{
								fontSize: "80px",
								fontWeight: "700",
								textAlign: "center",
								marginTop: "-2%",
							}}
						>
							Tomato early blight
						</p>
						<p
							style={{
								fontSize: "40px",
								fontWeight: "400",
								textAlign: "center",
								marginTop: "-5%",
								width: "40vw",
							}}
						>
							Please take immeadiate action to get back your healthy crops
							<span onClick={() => navigate('/learn')} style={{marginLeft: "10px", fontSize: "30px", color: "blue"}}>Learn More</span>
						</p>
					</div>
				</div>
				<div style={{ marginTop: "-8%" }}>
					<img src={ResultSVG} alt="alt" style={{ width: "100%" }} />
				</div>
			</div>
		</>
	);
}

export default Result;
