import React, { useEffect, useRef, useState } from "react";
import Hero_illustration from "../assets/Hero_illustration.svg";
import ScrollOneIllustration from "../assets/Group.svg";
import ScrollTwoIllustration from "../assets/Group (1).svg";
import {
	ButtonStyles,
	HeroDiv_1,
	HeroDiv_2,
	HeroDiv_3,
	HeroTypography,
  innerContainerStyle,
} from "./Home.styled";
import { useNavigate } from "react-router-dom";

function Home() {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrollDirection, setScrollDirection] = useState("UP");
	const scrollClassName = useRef(null);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
		console.log(position);
	};
	let oldValue = 0;
	let newValue;

	const handleDirection = () => {
		newValue = window.pageYOffset;
		if (oldValue - newValue < 0) {
			setScrollDirection("UP");
		} else if (oldValue - newValue > 0) {
			setScrollDirection("DOWN");
		}
		oldValue = newValue;
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });
		window.addEventListener("scroll", handleDirection);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (scrollPosition >= 300) {
			scrollClassName.current = "active";
		} else if (scrollPosition < 300 && scrollDirection === "DOWN") {
			scrollClassName.current = "back";
		}
	}, [scrollPosition]);

  const navigate = useNavigate();

	return (
		<>
				<div style={HeroDiv_1}>
					<div className="wrapper">
						<img
							src={Hero_illustration}
							alt="Hero_illustration"
							className={scrollClassName.current}
						/>
					</div>
				</div>
				<div style={HeroDiv_3}>
					<h1 style={HeroTypography}>PLANT DISEASE FINDER</h1>
					<div
						style={innerContainerStyle}
					>
						<div>
							<img src={ScrollTwoIllustration} alt="alt" />
						</div>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								margin: "2rem 4rem",
							}}
						>
							<p
								style={{
									fontSize: "30px",
									fontWeight: "400",
									letterSpacing: "0.10em",
								}}
							>
								Use our AI-powered plant disease finder to findout which disease
								your plant is afflicted with. Upload the image of the leaf of
								the crop and check if your crop is healthy or not!
							</p>
							<div style={{ marginTop: "2rem", display: "flex" }}>
								<button style={ButtonStyles} onClick={() => navigate('/predict')} >Start Now!</button>
							</div>
						</div>
					</div>
				</div>
		</>
	);
}

export default Home;
