import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Predict from "./pages/Predict";
import Result from "./pages/Result";
import Learn from "./pages/Learn";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" exact element={<Home />} />
				<Route path="/predict" exact element={<Predict />} />
        <Route path="/result" exact element={<Result />} />
        <Route path="/learn" exact element={<Learn />} />

			</Routes>
		</BrowserRouter>
	);
}

export default App;
